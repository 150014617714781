import React from 'react';
import type { IconProps } from '../types';

export const Undo = (props: IconProps) => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    {...props}
  >
    <g
      id="Page-1"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="Toolbar"
        transform="translate(-1375, -246)"
      >
        <g
          id="undo"
          transform="translate(1373, 244)"
        >
          <g
            id="icon-undo"
            transform="translate(2, 2)"
          >
            <rect
              id="Rectangle"
              x="0"
              y="0"
              width="20"
              height="20"
            ></rect>
            <path
              d="M7.35208562,2 C7.15515629,2 6.92990335,2.09653697 6.77177033,2.25466008 L1.74936988,7.20233994 C1.59094799,7.35387392 1.5,7.57447116 1.5,7.79610318 C1.5,8.02332566 1.58842308,8.23591732 1.75201182,8.39243102 L6.77309942,13.3388655 C6.92990335,13.4956694 7.15515629,13.5922064 7.35208562,13.5922064 C7.81787582,13.5922064 8.11909989,13.2870188 8.11909989,12.8345225 C8.11909989,12.6063308 8.0418091,12.4315864 7.88716003,12.2768955 L5.74465493,10.1984823 L4.25178995,8.93887748 L3.68918984,8.46418364 L4.42451621,8.49806964 L6.43770582,8.59110867 L11.8680022,8.59110867 C14.688432,8.59110867 16.4379802,10.2154905 16.4379802,12.6945664 C16.4379802,15.1936455 14.678791,16.863337 11.8680022,16.863337 L11.8059824,16.863337 C11.3318199,16.863337 10.9923161,17.2049494 10.9923161,17.6583425 C10.9923161,18.1117355 11.3318199,18.453348 11.8059824,18.453348 L11.9519759,18.453348 C15.7113807,18.453348 18,16.2610136 18,12.7692097 C18,9.27273433 15.6408003,7.00109769 11.8493414,7.00109769 L6.44921434,7.00083266 L4.42451621,7.09413672 L3.68918984,7.12802273 L4.25178995,6.65332889 L5.73143708,5.40570972 L7.88441979,3.30867885 C8.04444784,3.1486508 8.11909989,2.98311799 8.11909989,2.75768386 C8.11909989,2.30518755 7.81787582,2 7.35208562,2 Z"
              id="Path"
              fill="currentColor"
              fillRule="nonzero"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Undo;
